import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Vaccinations from "../../../components/resources/Vaccinations";

const VaccinationForAdults = () => {
  return (
    <Layout>
      <PageHeader text="Vaccination For Adults" />
      <Vaccinations />
      <Conditions />
    </Layout>
  );
};

export default VaccinationForAdults;
