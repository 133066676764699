import React from "react";

const Vaccinations = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Department of Health and Human Services Centers for Disease Control
          and Prevention
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Shingles Vaccination: What You Need to Know
        </p>
        <p className="text-md text-gray-600 mb-2">
          The vaccine for shingles (Zostavax®) is licensed for use in people 60
          years old and older to prevent shingles. The older a person is, the
          more severe the effects of shingles typically are, so all adults 60
          years old or older should get the shingles vaccine.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The shingles vaccine is specifically designed to protect people
          against shingles and will not protect people against other forms of
          herpes, such as genital herpes. The shingles vaccine is not
          recommended to treat active shingles or post-herpetic neuralgia (pain
          after the rash is gone) once it develops.
        </p>

        <p className="text-xl text-gray-800 mb-2">Disease Protection</p>
        <p className="text-md text-gray-600 mb-2">
          In a clinical trial involving thousands of adults 60 years old or
          older, Zostavax reduced the risk of shingles by about half (51%) and
          the risk of post-herpetic neuralgia by 67%. While the vaccine was most
          effective in people 60-69 years old it also provided some protection
          for older groups.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Research suggests that the shingles vaccine is effective for at least
          six years, but may last much longer. Ongoing studies are being
          conducted to determine exactly how long the vaccine protects against
          shingles.
        </p>

        <p className="text-xl text-gray-800 mb-2">Who Should Get the Vaccine</p>
        <p className="text-md text-gray-600 mb-2">
          CDC recommends Zostavax for use in people 60 years old and older to
          prevent shingles. This is a one-time vaccination. There is no maximum
          age for getting the shingles vaccine.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Anyone 60 years of age or older should get the shingles vaccine,
          regardless of whether they recall having had chickenpox or not.
          Studies show that more than 99% of Americans ages 40 and older have
          had chickenpox, even if they don’t remember getting the disease.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your risk for getting shingles begins to rise around age 50. However,
          shingles vaccine (Zostavax) is only recommended for persons age 60 and
          older because the safety and effectiveness of the vaccine were only
          studied in this age group.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Even if you have had shingles, you can still receive the shingles
          vaccine to help prevent future occurrences of the disease. There is no
          specific time that you must wait after having shingles before
          receiving the shingles vaccine. The decision on when to get vaccinated
          should be made with your health care provider. Generally, a person
          should make sure that the shingles rash has disappeared before getting
          vaccinated.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Who Should NOT Get the Vaccine
        </p>
        <p className="text-md text-gray-600 mb-2">
          Some people should NOT get shingles vaccine.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            A person who has ever had a life-threatening or severe allergic
            reaction to gelatin, the antibiotic neomycin, or any other component
            of shingles vaccine. Tell your doctor if you have any severe
            allergies.
          </li>
          <li>
            A person who has a weakened immune system because of HIV/AIDS or
            another disease that affects the immune system, treatment with drugs
            that affect the immune system, such as steroids, treatment with
            drugs that affect the immune system, such as steroids, cancer
            treatment such as radiation or chemotherapy, a history of cancer
            affecting the bone marrow or lymphatic system, such as leukemia or
            lymphoma.
          </li>
          <li>Women who are or might be pregnant</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Depression takes all the positivity, optimism, and happiness out of
          your life. Every day becomes a struggle, and it might seem like
          there’s little worth living for.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Anxiety is another perfectly normal human emotion everyone feels at
          times, but if you’re always anxious or fearful, you could have an
          anxiety disorder. You might have anxiety that doesn’t focus on
          anything in particular, which is called a generalized anxiety
          disorder. Or you might have anxiety that focuses on something
          specific, which is the case with social anxiety and phobias.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Possible Reactions to Vaccination
        </p>
        <p className="text-md text-gray-600 mb-2">
          No serious problems have been identified with shingles vaccine.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The vaccine has been tested in about 20,000 people aged 60 years old
          and older. The most common side effects in people who got the vaccine
          were redness, soreness, swelling or itching at the shot site, and
          headache. CDC, working with the FDA, will continue to monitor the
          safety of the vaccine after it is in general use.
        </p>
        <p className="text-md text-gray-600 mb-2">
          It is safe to be around infants and young children, pregnant women, or
          people with weakened immune systems after you get the shingles
          vaccine. There is no documentation of a person getting chickenpox from
          someone who has received the shingles vaccine (which contains
          varicella zoster virus).
        </p>
        <p className="text-md text-gray-600 mb-2">
          Some people who get the shingles vaccine will develop a
          chickenpox-like rash near the place where they were vaccinated. As a
          precaution, this rash should be covered until it disappears.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The shingles vaccine does not contain thimerosal (a preservative
          containing mercury).
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Reimbursement for Vaccination
        </p>
        <p className="text-md text-gray-600 mb-2">
          All Medicare Part D plans cover the shingles vaccine. The amount of
          cost-sharing (money you have to pay) for vaccination varies.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Medicare Part B does not cover the shingles vaccine. If you have
          private insurance or Medicaid, your plan may or may not cover the
          vaccine; contact your insurer to find out.
        </p>
      </div>
    </div>
  );
};

export default Vaccinations;
